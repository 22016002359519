import "./index.scss";

const Main = () => {
  return (
    <div className="Main">
      <p>PT ZAUNE</p>
      <p>Zaune gelander tore</p>
      <p>Produkcja i montaż</p>
    </div>
  );
};

export default Main;
