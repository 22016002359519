import "./index.scss";

const Top = () => {
  return (
    <div className="Top">
      <div className="logo">PT ZAUNE ((LOGO))</div>
      <div className="langChange">
        <img src="https://upload.wikimedia.org/wikipedia/en/1/12/Flag_of_Poland.svg" />
        <img src="https://upload.wikimedia.org/wikipedia/en/thumb/b/ba/Flag_of_Germany.svg/1200px-Flag_of_Germany.svg.png" />
      </div>
    </div>
  );
};

export default Top;
