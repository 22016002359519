import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import frontImg from "./../../assets/gallery/front.jpg";

import "./index.scss";

const Gallery = () => {
	return (
		<div className="Gallery">
			<Carousel width={"100%"} showStatus={false}>
				<div>
					<img src={frontImg} alt="" />
				</div>
				<div>
					<img src={frontImg} alt="" />
				</div>
				<div>
					<img src={frontImg} alt="" />
				</div>
			</Carousel>
		</div>
	);
};

export default Gallery;
