import "./index.scss";

const Aside = () => {
  return (
    <div className="Aside">
      <p>
        Firma PT Zäune Wir sind ein kompetenter Partner, wenn es um Montage der
        Metallzäune Tore, Pforten und Geländer geht. Wir bieten hochwertige
        Produkte mit Deutscher Qualität zu polnischen Preisen an. Wir bestehen
        seit 8 Jahren auf dem Markt. Wir führen die Montage mit kundeneigenem
        oder eigenem Material durch. Zusätzlich bieten wir die Möglichkeit, das
        benötigte Zaunmaterial bei uns zu bestellen. Rufen Sie uns an, wenn Sie
        noch Fragen haben
      </p>
      <b>Tel.+4915781884671</b>
    </div>
  );
};

export default Aside;
