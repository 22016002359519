import "./App.scss";
import Aside from "./components/Aside";
import Gallery from "./components/Gallery";
import Main from "./components/Main";
import Nav from "./components/Nav";
import Top from "./components/Top";
import Tiles from "./components/Tiles";

function App() {
  return (
    <>
      <Top />
      <Nav />
      <Main />
      <Tiles />
      <div className="container">
        <Aside />
        <Gallery />
      </div>
    </>
  );
}

export default App;
