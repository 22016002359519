import "./index.scss";

const Nav = () => {
  return (
    <div className="Nav">
      <button>strona główna</button>
      <button>galeria</button>
      <button>opinie</button>
      <button>kontakt</button>
    </div>
  );
};

export default Nav;
