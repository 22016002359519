import "./index.scss";

const Tiles = () => {
	return (
		<div className="Tiles">
			<div className="tile">
				<p>Ogrodzenia</p>
			</div>
			<div className="tile">
				<p>Balustrady</p>
			</div>
			<div className="tile">
				<p>Bramy</p>
			</div>
		</div>
	);
};

export default Tiles;
